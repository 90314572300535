<template>
    <div class="vehicle-top">
        <div class="category">
            <div class="layout">
                <div class="flex xs3 total">
                    <div class="layout">
                        <market-stats
                            title="Avg Spend Per VIN"
                            class="pt-0 fb-category-total"
                            style=" margin:auto;"
                            :metric="vehicleData.avgSpendPerVIN.avg | numFormat('$0,0')" />
                    </div>
                    <div class="layout">
                        <div class="flex offset-xs3 xs3">
                            <market-stats
                                title="New"
                                class="pt-0 fb-sm-catagory"
                                :metric="vehicleData.avgSpendPerVIN.new | numFormat('$0,0')" />
                        </div>
                        <div class="flex xs3">
                            <market-stats
                                title="Used"
                                class="pt-0 fb-sm-catagory"
                                :metric="vehicleData.avgSpendPerVIN.used | numFormat('$0,0')" />
                        </div>
                    </div>
                    <div class="layout">
                        <market-stats
                            title="Total vehicle Spend"
                            class="pt-0 fb-category-total"
                            style=" margin:auto;"
                            :metric="vehicleData.avgSpendPerVIN.total | numFormat('$0,0')" />
                    </div>
                </div>

                <div class="flex xs3 total">
                    <div class="layout">
                        <market-stats
                            title="Avg Days Advertised"
                            class="pt-0 fb-category-total"
                            style=" margin:auto;"
                            :metric="vehicleData.avgDaysAdvertised.avg | numFormat(numberFormats.number)" />
                    </div>
                    <div class="layout">
                        <div class="flex offset-xs3 xs3">
                            <market-stats
                                title="New"
                                class="pt-0 fb-sm-catagory"
                                :metric="vehicleData.avgDaysAdvertised.new | numFormat(numberFormats.number)" />
                        </div>
                        <div class="flex xs3">
                            <market-stats
                                title="Used"
                                class="pt-0 fb-sm-catagory"
                                :metric="vehicleData.avgDaysAdvertised.used | numFormat(numberFormats.number)" />
                        </div>
                    </div>
                    <div class="layout">
                        <market-stats
                            title="Range of days advertised"
                            class="pt-0 fb-category-total"
                            style=" margin:auto;"
                            :metric="vehicleData.avgDaysAdvertised.range" />
                    </div>
                </div>

                <div class="flex xs3 total">
                    <div class="layout">
                        <market-stats
                            title="AVG FORM LEADS PER VIN"
                            class="pt-0 fb-category-total"
                            style=" margin:auto;"
                            :metric="vehicleData.avgFormLeads.avg | numFormat(numberFormats.number)" />
                    </div>
                    <div class="layout">
                        <div class="flex offset-xs3 xs3">
                            <market-stats
                                title="New"
                                class="pt-0 fb-sm-catagory"
                                :metric="vehicleData.avgFormLeads.new | numFormat(numberFormats.number)" />
                        </div>
                        <div class="flex xs3">
                            <market-stats
                                title="Used"
                                class="pt-0 fb-sm-catagory"
                                :metric="vehicleData.avgFormLeads.used | numFormat(numberFormats.number)" />
                        </div>
                    </div>
                    <div class="layout">
                        <market-stats
                            title="Total Form Leads"
                            class="pt-0 fb-category-total"
                            style=" margin:auto;"
                            :metric="vehicleData.avgFormLeads.total | numFormat(numberFormats.number)" />
                    </div>
                </div>

                <div class="flex xs6">
                    <div class="layout model-stats-container">
                        <div class="flex xs12">
                            <div class="layout">
                                <div class="flex xs12 ml-4">
                                    <h4>MAKE MODEL STATS</h4>
                                </div>
                            </div>
                            <div class="layout model-stats">
                                <div class="flex xs4 text-xs-right">
                                    <span class="text-uppercase">Top Impressions</span>
                                </div>
                                <div class="flex xs1">
                                    <img
                                        class="logo"
                                        :src="vehicleData.icons.topImpressions">
                                </div>
                                <div class="flex text-xs-left">
                                    <a
                                        href="#"
                                        @click="showVehiclePanel(vehicleData.vehicleIds.topImpressions)">
                                        <span class="text-uppercase model-stats-desc">{{ vehicleData.stats.topImpressions }}</span>
                                    </a>
                                </div>
                            </div>
                            <div class="layout model-stats">
                                <div class="flex xs4 text-xs-right">
                                    <span class="text-uppercase">Top Clicks</span>
                                </div>
                                <div class="flex xs1">
                                    <img
                                        class="logo"
                                        :src="vehicleData.icons.topClicks">
                                </div>
                                <div class="flex text-xs-left">
                                    <a
                                        href="#"
                                        @click="showVehiclePanel(vehicleData.vehicleIds.topClicks)">
                                        <span class="text-uppercase model-stats-desc">{{ vehicleData.stats.topClicks }}</span>
                                    </a>
                                </div>
                            </div>
                            <div class="layout model-stats">
                                <div class="flex xs4 text-xs-right">
                                    <span class="text-uppercase">Top Form Leads</span>
                                </div>
                                <div class="flex xs1">
                                    <img
                                        class="logo"
                                        :src="vehicleData.icons.topFormLeads">
                                </div>
                                <div class="flex text-xs-left">
                                    <a
                                        href="#"
                                        @click="showVehiclePanel(vehicleData.vehicleIds.topFormLeads)">
                                        <span class="text-uppercase model-stats-desc">{{ vehicleData.stats.topFormLeads }}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MarketStats from '@/components/globals/MarketStats';
import getNumberFormats from '@/helpers/numberFormats';

export default {
    components: {
        MarketStats,
    },
    props: {
        value: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            numberFormats: getNumberFormats(),
            updatedAt: new Date(),
        };
    },
    computed: {
        vehicleData() {
            if (!this.value) {
                return null;
            }

            return {
                avgSpendPerVIN: {
                    used: this.value?.avgSpendPerVINData?.used || 0,
                    new: this.value?.avgSpendPerVINData?.new || 0,
                    total: this.value?.avgSpendPerVINData?.total || 0,
                    avg: this.value?.avgSpendPerVINData?.avg || 0,
                },
                avgDaysAdvertised: {
                    used: this.value?.avgDaysAdvertisedData?.used || 0,
                    new: this.value?.avgDaysAdvertisedData?.new || 0,
                    range: this.value?.avgDaysAdvertisedData?.range || 0,
                    avg: this.value?.avgDaysAdvertisedData?.avg || 0,
                },
                avgVDPPerVIN: {
                    used: this.value?.avgVDPPerVINData?.used || 0,
                    new: this.value?.avgVDPPerVINData?.new || 0,
                    total: this.value?.avgVDPPerVINData?.total || 0,
                    avg: this.value?.avgVDPPerVINData?.avg || 0,
                },
                avgFormLeads: {
                    used: this.value?.avgFormLeads?.used || 0,
                    new: this.value?.avgFormLeads?.new || 0,
                    total: this.value?.avgFormLeads?.total || 0,
                    avg: this.value?.avgFormLeads?.avg || 0,
                },
                stats: {
                    topImpressions: this.value?.statsData?.topImpressions || '',
                    topContentViews: this.value?.statsData?.topContentViews || '',
                    topClicks: this.value?.statsData?.topClicks || '',
                    topLeads: this.value?.statsData?.topLeads || '',
                    topFormLeads: this.value?.statsData?.topFormLeads || '',
                },
                icons: {
                    topImpressions: this.getStatsIcon(this.value?.statsIcon?.topImpressions),
                    topContentViews: this.getStatsIcon(this.value?.statsIcon?.topContentViews),
                    topClicks: this.getStatsIcon(this.value?.statsIcon?.topClicks),
                    topLeads: this.getStatsIcon(this.value?.statsIcon?.topLeads),
                    topFormLeads: this.getStatsIcon(this.value?.statsIcon?.topFormLeads),
                },
                vehicleIds: {
                    topImpressions: this.value?.vehicleId?.topImpressions || '',
                    topContentViews: this.value?.vehicleId?.topContentViews || '',
                    topClicks: this.value?.vehicleId?.topClicks || '',
                    topLeads: this.value?.vehicleId?.topLeads || '',
                    topFormLeads: this.value?.vehicleId?.topFormLeads || '',
                }
            };
        },
    },
    methods: {
        getStatsIcon(value) {
            if (value === 'Truck' || value === 'Extended Cab Chassis-Cab') {
                return '/img/vehicle-type-4.svg';
            } else if (value === 'Sedan' || value === '2dr Car') {
                return '/img/vehicle-type-3.svg';
            } else if (value === 'Coupe' || value === 'Convertible') {
                return '/img/vehicle-type-2.svg';
            } else if (value === 'Wagon' || value === 'SUV' || value === 'Hatchback' || value === 'Mini-Van') {
                return '/img/vehicle-type-1.svg';
            }
        },
        showVehiclePanel(vehicle_id) {
            const vehicleItem = { vehicle_id };
            this.$emit('showVehiclePanel', vehicleItem);
        },
    }
};
</script>

<style lang="scss" scoped>
.vehicle-top {
    margin:20px;

    .vehicle-header {
        padding: 10px 0 30px;
        @media (min-width: $bp-md) {
            display: flex;
            align-items: center;
        }
    }

    .category {
        margin-bottom: 30px;
        font-size: 12px;

        @media (min-width: $bp-md) {
            display: flex;
            align-items: center;
            margin-bottom: 0;
        }

        .fb-sm-catagory {
            font-size:10px;
        }
        .badge {
            margin: 30px;
        }

        .total {
            padding-top:40px;
        }
    }
    .model-stats-container {
        h4 {
            font-size: 14px;
        }

        .model-stats {
            margin:50px 10px 0px 10px;

            div.text-xs-right {
                padding-right:40px;
            }

            div.text-xs-left {
                padding-left:15px;
            }

            .model-stats-desc {
                color: $light-blue-text;
            }
        }
    }
}

</style>

<style lang="scss">
.vehicle-header:not(.vehicle-header--dealer-level) .stats {
    .stats-element-title {
        span {
            color:#848484;
        }
    }
}

.fb-sm-catagory {
    h4 {
        font-size:16px;
    }
}

.fb-category-total {
    .stats-element-value {
        padding-top:10px;
    }
}
</style>
